import React, { useState,useRef, useEffect, useContext}from "react";
// import { Container, Row, Col } from "react-bootstrap";

import axios from "axios";
import { useToasts } from "react-toast-notifications";

import { Link } from "react-router-dom";

import Navbars from "../navbar/Navbar";
import "./Home.css";
import Loginform from "../login/Login";
import Loader from "../assets/gif.gif"

import  {userDataContext} from "../App"
import { Row, Col } from "antd";


const Home = () => {
  const [userType, setuserType] = React.useState(true);

  
  const Ref = useRef(null);
  
  // The state for our timer
  const [timer, setTimer] = useState('00:00:00');

  const [loginInfo,setLoginInfo] = useState(false)

  const { user } = useContext(userDataContext);


  const { addToast } = useToasts();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isModalOpen1, setIsModalOpen1] = React.useState(false);
  const [databalance, setData] = React.useState([]);
  const [decisionModal, setisDecisionModal] = React.useState(false);

  useEffect(() => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    axios
      .get("https://www.otcfinanceuk.com/otc/otc/api/balance/allbalance", {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.data)
          return setData(res.data.data);
        }

        // window.location = "/retrieve"; //This line of code will redirect you once the submission is succeed
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showDecision = () => {
    setisDecisionModal(true);
  };

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalOpen1(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalOpen1(false);
    setisDecisionModal(false);
  };

  const [amount, setAmount] = useState("");
  const [bankname, setBankname] = useState("");
  const [accountname, setAccountname] = useState("");
  const [accountnumber, setAcccountnumber] = useState("");
  const [routing, setRouting] = useState("");
  const [swiftcode, setSwiftcode] = useState("");
  const [transact, setTransact] = useState("");

  const [transaction, setTransaction] = useState([]);

  React.useEffect(() => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    axios
      .get("https://www.otcfinanceuk.com/otc/otc/api/transact/alltransaction", {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          return setTransaction(res.data.data), console.log(transaction);
        }

        // window.location = "/retrieve"; //This line of code will redirect you once the submission is succeed
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return addToast("You cannot add more than one balance type", {
            appearance: "error",
          });
        } else if (error.response.status === 500) {
          return addToast("Error adding balance", {
            appearance: "warning",
          });
        }
      });
  }, [transact]);

  const submitHandler = (event) => {
    event.preventDefault();

    const transact = {
      amount: amount,
      bankname: bankname,
      accountname: accountname,
      accountnumber: accountnumber,
      routing: routing,
      swiftcode: swiftcode,
      transactiontype: "fiat",
    };

    console.log(transact);

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    axios
      .post(
        "https://www.otcfinanceuk.com/otc/otc/api/transact/addtransact",
        transact,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          return (
            setTransact(res.data),
            handleCancel(),
            addToast(" Transfer made successfully", {
              appearance: "success",
            })
          );
        }

        // window.location = "/retrieve"; //This line of code will redirect you once the submission is succeed
      })
      .catch((error) => {
        if (error.response.status === 500) {
          return addToast("Error sending funds", {
            appearance: "error",
          });
        } else if (error.response.status === 402) {
          return addToast("Insuficient Funds", {
            appearance: "error",
          });
        } else {
          return addToast("Insuficient FundsError sending funds", {
            appearance: "error",
          });
        }
      });
  };

  const submitcryptoHandler = (event) => {
    event.preventDefault();

    const transact = {
      amount: amount,
      accountname: accountname,
      transactiontype: "fiat",
    };

    console.log(transact);

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    axios
      .post(
        "https://www.otcfinanceuk.com/otc/otc/api/transact/addtransact",
        transact,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          return (
            setTransact(res.data),
            handleCancel(),
            addToast(" Transfer made successfully", {
              appearance: "success",
            })
          );
        }

        // window.location = "/retrieve"; //This line of code will redirect you once the submission is succeed
      })
      .catch((error) => {
        if (error.response.status === 500) {
          return addToast("Error sending funds", {
            appearance: "error",
          });
        } else if (error.response.status === 402) {
          return addToast("Insuficient Funds", {
            appearance: "error",
          });
        } else {
          return addToast("Insuficient FundsError sending funds", {
            appearance: "error",
          });
        }
      });
  };


  return (
    <div className="body">
      <Navbars />

      <div className="bodyconttainer">
        <Row className="justify-content-center">
          <Col className="detailsside"  xs={24} sm={12} lg={12}>

         
            <h2>BO FANG INVESTMENT</h2>
            <p>
              Financial analysis
            </p>
            <ul >
              <li>HIMP deposit: 
              {databalance[0]?.balancetype === "crypto"
                ? databalance[0]?.amount
                : databalance[0]?.balancetype === "fiat"
                ? databalance[1]?.amount
                : null}
              BTC</li>
              <li> Aviailable Balance:
              {databalance[0]?.balancetype === "fiat"
                ? databalance[0]?.amount
                : databalance[0]?.balancetype === "crypto"
                ? databalance[1]?.amount
                : null}
              BTC</li>
            </ul>
       
          </Col>

          <Col className="formside"  xs={24} sm={12} lg={12}>
            {userType ? (
              <>
                <h2>Login to your account</h2>

                { user ?    <h2 style={{ color:"red"}}> <img src={Loader} alt="Loading"/></h2> : <Loginform />}
             
                
     
              </>
            ) : (
              <>
                <h2>Sign into your account</h2>
         
              </>
            )}

           

            <p className="terms">
              By logining into your OTC finance  account or continuing to use a application,
              website, or software, you acknowledge and agree that you have 
              accepted the and have reviewed the .
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
